import React from 'react'
import { Link } from 'gatsby'
import { Shop } from 'grommet-icons'
import facebook from '../img/social/facebook.svg'
import logo from '../img/logo.svg'

const Navbar = class extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      active: false,
      navBarActiveClass: '',
    }
  }

  toggleHamburger = () => {
    // toggle the active boolean in the state
    this.setState(
      {
        active: !this.state.active,
      },
      // after state has been updated,
      () => {
        // set the class in state for the navbar accordingly
        this.state.active
          ? this.setState({
              navBarActiveClass: 'is-active',
            })
          : this.setState({
              navBarActiveClass: '',
            })
      }
    )
  }

  render() {
    return (
      <React.Fragment>
      <nav
        className="navbar is-transparent"
        role="navigation"
        aria-label="main-navigation"
      >
        <div className="container">
          <div className="navbar-brand">
            <Link to="/" className="navbar-item" title="Logo">
              <img src={logo} alt="Big Thicket Association" style={{ width: '240px' }} />
            </Link>
            {/* Hamburger menu */}
            <div
              className={`navbar-burger burger ${this.state.navBarActiveClass}`}
              data-target="navMenu"
              onClick={() => this.toggleHamburger()}
            >
              <span />
              <span />
              <span />
            </div>
          </div>
          <div
            id="navMenu"
            className={`navbar-menu ${this.state.navBarActiveClass}`}
          >
            <div className="navbar-start has-text-centered">
              <Link className="navbar-item" to="/about">
                About Us
              </Link>
              <Link className="navbar-item" to="/membership">
                Join
              </Link>
              <Link className="navbar-item" to="/donations">
                Donate
              </Link>
              <Link className="navbar-item" to="/volunteering">
                Volunteer
              </Link>
              <Link className="navbar-item" to="/events">
                Events
              </Link>
              <Link className="navbar-item" to="/bulletins">
                Bulletins
              </Link>
              <Link className="navbar-item" to="/blog">
                Blog
              </Link>
              <Link className="navbar-item" to="/contact">
                Contact
              </Link>
            </div>
            <div className="navbar-end has-text-centered">
              <a
                className="navbar-item"
                href="http://facebook.com/BigThicketAssociation"
                target="_blank"
                rel="noopener noreferrer"
              >
                <span className="icon">
                  <img src={facebook} alt="Facebook BTA" />
                </span>
                &nbsp;&nbsp;BTA
              </a>
              <a
                className="navbar-item"
                href="http://facebook.com/pages/Neches-River-Adventures/209311866070"
                target="_blank"
                rel="noopener noreferrer"
              >
                <span className="icon">
                  <img src={facebook} alt="Facebook N.R. Adventures" />
                </span>
                &nbsp;&nbsp;N.R.Adventures
              </a>
	      <a
                className="navbar-item"
		href="https://www.facebook.com/NechesRiverRally"
                target="_blank"
                rel="noopener noreferrer"
              >
                <span className="icon">
                  <img src={facebook} alt="Facebook N.R. Rally" />
                </span>
                &nbsp;&nbsp;N.R. Rally
              </a>
            </div>
          </div>
        </div>
      </nav>
      <nav
        className="navbar is-transparent"
        role="navigation"
        aria-label="main-navigation"
      >
        <div className="container">
          <div
            id="navMenu"
            className={`navbar-menu ${this.state.navBarActiveClass}`}
          >
            <div className="navbar-start has-text-centered">
              <Link className="navbar-item" to="/">
                <Shop />
              </Link>
              <a
                className="navbar-item"
                href="https://www.nechesriveradventures.org"
                target="_blank"
                rel="noopener noreferrer"
              >
                Ivory Bill Boat Tours
              </a>
              <a
                className="navbar-item"
                href="https://www.thicketofdiversity.org"
                target="_blank"
                rel="noopener noreferrer"
              >
                Thicket of Diversity / Field Station Registration
              </a>
              <Link className="navbar-item" to="/rally">
                Neches River Rally
              </Link>
            </div>
          </div>
        </div>
      </nav>
      </React.Fragment>
    )
  }
}

export default Navbar
